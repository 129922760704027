import * as React from 'react';
import * as Styled from './dedicatedCustomerCareStyles';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SECTIONS } from '../../constants';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const CustomerCare = ({ customerCareData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.DedicatedCustomerCareHeading>
          {children}
        </Styled.DedicatedCustomerCareHeading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.DedicatedCustomerCareParagraph>
          {children}
        </Styled.DedicatedCustomerCareParagraph>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <Styled.DedicatedCustomerCareLinkStyle>
          {children}
        </Styled.DedicatedCustomerCareLinkStyle>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.DedicatedCustomerCareLinkStyle
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.DedicatedCustomerCareLinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          asset?.node?.title?.includes('service') ? (
            <Styled.HomeCustomerCareGraphicsCCE
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          ) : (
            <Styled.HomeCustomerCareGraphicsTRUCK
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          )
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const optionsDriverImageStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <></>,

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.HomeCustomerCareGraphicsDriver
            $imageUrl={asset?.node?.file?.url}
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once
            data-aos-duration="1000"
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const imageList = customerCareData?.filter((list) => {
    return (
      list?.type === SECTIONS.HOMEPAGE.DEDICATED_CUSTOMER_CARE.IMAGE_CENTER
    );
  })[0];
  const paragraphList = customerCareData?.filter((list) => {
    return (
      list?.type ===
      SECTIONS.HOMEPAGE.DEDICATED_CUSTOMER_CARE.RICHTEXTWITHPARAGRAPH
    );
  })[0];
  const imageCenter = customerCareData?.filter((list) => {
    return (
      list?.type === SECTIONS.HOMEPAGE.DEDICATED_CUSTOMER_CARE.SINGLE_IMAGE
    );
  })[0];

  return (
    <React.Fragment>
      <Styled.HomeCustomerCare>
        <Styled.HomeCustomerCareGraphics>
          {imageList?.contentDetails?.raw &&
            documentToReactComponents(
              JSON.parse(imageList?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.HomeCustomerCareGraphics>
        {imageList?.contentDetails?.raw &&
          documentToReactComponents(
            JSON.parse(imageCenter?.contentDetails?.raw),
            optionsDriverImageStyle
          )}
        <Styled.HomeCustomerCareGraphicsText>
          {paragraphList &&
            documentToReactComponents(
              JSON.parse(paragraphList?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.HomeCustomerCareGraphicsText>
      </Styled.HomeCustomerCare>
    </React.Fragment>
  );
};

export default CustomerCare;
